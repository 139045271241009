
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 8em;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;

  background: -webkit-linear-gradient(330deg, #ff00d4, #860270);
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent;
  text-align: center;

}

